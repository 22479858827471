<template>
    <div class="pointPosition">
        <h1>点位管理</h1>
        <el-form :inline="true">
            <el-form-item label="名称">
                <el-input v-model="searchParam.nameLike" style="width: 180px;" />
            </el-form-item>
            <el-form-item label="点位类型">
                <el-select v-model="searchParam.type" filterable style="width: 120px;" clearable>
                    <el-option v-for='pointTypeItem in show.pointTypeList' :key="pointTypeItem.id" :label="pointTypeItem.value" :value="pointTypeItem.value" />
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button-group>
                    <el-button type="primary" @click="commitFindList()">搜索</el-button>
                    <el-button type="info" @click="exportExcel()">导出</el-button>
                    <el-button @click="resetSearchParam()">重置</el-button>
                </el-button-group>
            </el-form-item>
            <el-form-item>
                <el-button-group>
                    <el-button type="primary" @click="data.editData = {}; show.editShow = true">新增</el-button>
                    <el-button type="info" @click="show.importShow = true">导入</el-button>
                    <el-button type="warning" :disabled="!(show.selected.length == 1)" @click="update()">编辑</el-button>
                    <el-button type="danger" :disabled="!(show.selected.length >= 1)" @click="del()">删除</el-button>
                </el-button-group>
            </el-form-item>
        </el-form>
        <el-table :data="data.list" @selection-change="selectionChange()" ref="tabRef" border style="height: calc(100vh - 310px);">
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column prop="name" label="名称" width="200" show-overflow-tooltip>
                <template #default="scope">
                    <el-link type="primary" @click="update(scope.row)">{{ scope.row.name }}</el-link>
                </template>
            </el-table-column>
            <el-table-column prop="areaCode" label="区划" width="100" show-overflow-tooltip />
            <el-table-column label="坐标" width="100" show-overflow-tooltip>
                <template #default="scope">
                    <el-text type="primary" v-if="scope.row.longitude && scope.row.latitude">已上传</el-text>
                    <el-text type="danger" v-else>未上传</el-text>
                </template>
            </el-table-column>
            <el-table-column prop="address" label="地址" show-overflow-tooltip />
        </el-table>
        <div style="height: 10px;" />
        <el-pagination v-model:current-page="searchParam.pageNum" v-model:page-size="searchParam.pageSize" :page-sizes="[50, 100, 200]" background layout="total, sizes, prev, pager, next, jumper" :total="data.total" @change="commitFindList()" />

        <el-dialog :close-on-click-modal="false" v-model="show.editShow" :title="data.editData.id ? '修改' : '新增'" width="900">
            <el-form label-width="auto">
                <el-form-item label="ID" v-show="false">
                    <el-input v-model="data.editData.id" />
                </el-form-item>
                <el-form-item label="名称">
                    <el-input v-model="data.editData.name" />
                </el-form-item>
                <el-form-item label="门头照片">
                    <el-upload accept="image/*" style="width: 100%;" v-model:file-list="data.file.doorPhoto" :action="api.baseURL() + '/backend/file/upload'" :headers="api.tokenHeader()" multiple drag :on-error="() => ElMessage.error('上传失败')" :limit="1"
                        :on-preview="(f) => util.previewElfile(f)">
                        <el-text>点击或拖转到此处上传文件</el-text>
                    </el-upload>
                </el-form-item>
                <el-form-item label="点位类型">
                    <el-select v-model="data.editData.type" filterable style="width: 100%;" clearable>
                        <el-option v-for='pointTypeItem in show.pointTypeList' :key="pointTypeItem.id" :label="pointTypeItem.value" :value="pointTypeItem.value" />
                    </el-select>
                </el-form-item>
                <el-form-item label="区划编码">
                    <el-select v-model="data.editData.areaCode" filterable remote reserve-keyword placeholder="输入名称搜索并选择" :remote-method="loadAreaCodeOptions" style="width: 100%">
                        <el-option v-for="item in show.areaCodeOptions" :key="item.areaCode" :label="item.fullName + '(' + item.areaCode + ')'" :value="item.areaCode" />
                    </el-select>
                </el-form-item>
                <el-form-item label="所属辖区">
                    <el-input v-model="data.editData.areaName" />
                </el-form-item>
                <el-form-item label="地址">
                    <el-input v-model="data.editData.address" />
                </el-form-item>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="经度" prop="longitude">
                            <el-input-number v-model="data.editData.longitude" :precision="6" :controls="false" style="width: 100%;" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="纬度" prop="latitude">
                            <el-input-number v-model="data.editData.latitude" :precision="6" :controls="false" style="width: 100%;" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="位置">
                    <AMapSearchPoint :point="mapPoint()" @choosePoint="choosePoint" style="width: 100%;" />
                </el-form-item>
                <el-form-item label="标准">
                    <el-select v-model="data.editData.standardIds" multiple placeholder="请选择">
                        <el-option
                        v-for="item in show.standardInfoList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                 <el-form-item label="责任单位">
                    <el-select v-model="data.editData.unitIds" multiple placeholder="请选择">
                        <el-option
                        v-for="item in show.responsibleUnitList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" @click="edit()">确认</el-button>
                </div>
            </template>
        </el-dialog>

        <el-dialog v-model="show.importShow" title="确认导入" width="500">
            <el-form>
                <el-form-item>
                    <el-text>请先</el-text>
                    <el-link type="primary" @click="importExcelTemplate()">下载导入模版</el-link>
                    <el-text>，填写模板内容再导入。</el-text>
                    <br>
                    <el-text type="danger">模板格式不对应可能造成倒入数据混乱，请注意。</el-text>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-upload :action="api.baseURL() + '/backend/pointPosition/importExcel'" :headers="api.tokenHeader()" :on-progress="importExcelProgress" :on-success="importExcelSuccess" :on-error="importExcelError" :show-file-list="false">
                        <el-button type="primary">导入</el-button>
                    </el-upload>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../apis/api'
import apiNative from '../../apis/apiNative'
import util from '../../utils/util'
import math from '../../utils/math'
import AMapSearchPoint from '../../components/AMapSearchPoint.vue'

const router = useRouter()
const tabRef = ref()

const data = reactive({
    total: 0,
    list: [],
    accumulate: {},
    editData: {},
    file: {},
    standardIds:[],
    unitIds:[]

})

const show = reactive({
    selected: [],
    importShow: false,
    pointTypeList: [],
    areaCodeOptions: [],
    standardInfoList:[],
    responsibleUnitList:[],
})

api.get('/backend/dict/getByCode', { params: { code: 'pointType' } }).then(res => {
    show.pointTypeList = res.dictList
})

const loadAreaCodeOptions = (keyword) => {
    const params = {
        keyword: keyword
    }
    api.get('/backend/ad/page', { params }).then(res => {
        show.areaCodeOptions = res.list
    })
}

const searchParam = reactive({})
const resetSearchParam = () => {
    searchParam.nameLike = null
    searchParam.type = null
    searchParam.pageNum = 1
    searchParam.pageSize = 50
}
resetSearchParam()

const getPageParam = () => {
    return JSON.parse(JSON.stringify(searchParam))
}

const commitFindList = () => {
    api.get('/backend/pointPosition/page', { params: getPageParam() }).then(res => {
        data.list = res.list
        data.total = res.total
    })
}
commitFindList()

const selectionChange = () => {
    show.selected = tabRef.value.getSelectionRows()
}

const update = (row) => {
    const pointPosition = row ? row : tabRef.value.getSelectionRows()[0] 
    data.editData = JSON.parse(JSON.stringify(pointPosition))
    data.file.doorPhoto = util.json2Elfile(data.editData.doorPhoto)// 图片数据转为展示
    data.editData.standardIds = data.editData.standardInfos.map(item => item.id);
    data.editData.unitIds = data.editData.responsibleUnits.map(item => item.id);
    loadAreaCodeOptions(data.editData.areaCode)
    api.get('/backend/ad/page', {
        params: {
            areaCode: data.editData.areaCode
        }
    }).then(res => {
        show.areaCodeOptions = res.list
    })
    show.editShow = true
}

const edit = () => {
    data.editData.doorPhoto = util.elfile2Json(data.file.doorPhoto)// 图片展示转为数据
    data.editData = JSON.parse(JSON.stringify(data.editData))
    if (data.editData.id) {
        submitUpdate()
    } else {
        submitAdd()
    }
}

const submitAdd = () => {
    api.post('/backend/pointPosition/add',data.editData).then(() => {
        ElMessage.success('添加成功')
        show.editShow = false
        commitFindList()
    })
}

const submitUpdate = () => {
    api.post('/backend/pointPosition/set', data.editData).then(() => {
        ElMessage.success('修改成功')
        show.editShow = false
        commitFindList()
    })
}

const del = () => {
    const ids = tabRef.value.getSelectionRows().map(pointPosition => pointPosition.id)
    ElMessageBox.confirm('', {
        title: '确认删除',
        message: '将要删除选中的 ' + ids.length + ' 项。',
        autofocus: false,
        confirmButtonText: '确认',
        cancelButtonText: '取消'
    }).then(() => {
        api.post('/backend/pointPosition/del', { ids: ids }).then(() => {
            ElMessage.success('删除成功')
            commitFindList()
        })
    })
}


const importExcelTemplate = () => {
    apiNative.get('/backend/pointPosition/importExcelTemplate', {
        responseType: 'blob'
    }).then(res => {
        ElMessage.success('开始下载')
        util.downloadFile(res, '点位信息-导入模版', 'xlsx')
    }).catch(() => {
        ElMessage.error('下载失败，请联系系统管理员')
    })
}

const importExcelProgress = () => {
    ElMessage.success('正在导入！如果数据量大可能时间较长，请耐心等待。')
}

const importExcelSuccess = (res) => {
    if (res.code != 'S000001') {
        ElMessage.error('导入失败，请联系系统管理员')
        return
    }
    show.importShow = false
    let message = '<p>本次导入，共计识别到：' + res.data.all + '条数据</p>'
    message += '<p>成功：<span style="color:green"> ' + res.data.success + ' </span>条数据</p>'
    message += '<p>失败：<span style="color:red"> ' + res.data.error + ' </span>条数据</p>'
    if (res.data.error > 0) {
        for (const failIndex in res.data.failRowList) {
            message += '<p>第 ' + res.data.failRowList[failIndex].row + ' 行：' + res.data.failRowList[failIndex].reason + '</p>'
        }
    }
    ElMessageBox.alert('', {
        title: '导入完成',
        message: message,
        autofocus: false,
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定'
    })
    res.data
}

const importExcelError = () => {
    ElMessage.error('导入失败，请联系系统管理员')
}

const exportExcel = () => {
    ElMessageBox.confirm('', {
        title: '确认导出',
        message: '将要按照当前选择的查询条件进行导出。',
        autofocus: false,
        confirmButtonText: '确认',
        cancelButtonText: '取消'
    }).then(() => {
        ElMessage.success('正在导出！如果数据量大可能时间较长，请耐心等待。')
        apiNative.get('/backend/pointPosition/exportExcel', {
            params: getPageParam(),
            responseType: 'blob'
        }).then(res => {
            searchParam.exportShow = false
            ElMessage.success('开始下载')
            util.downloadFile(res, '点位信息' + util.parseTime(new Date(), '{y}{m}{d}{h}{i}{s}'), 'xlsx')
        }).catch(() => {
            ElMessage.error('导出失败，请联系系统管理员')
        })
    })
}

const mapPoint = () => {
    if (data.editData.longitude && data.editData.latitude) {
        return [data.editData.longitude * 1, data.editData.latitude * 1]
    }
    return null
}

const choosePoint = (lnglat) => {
    if (lnglat) {
        data.editData.longitude = lnglat[0]
        data.editData.latitude = lnglat[1]
    } else {
        data.editData.longitude = null
        data.editData.latitude = null
    }
}
//TODO 下滑分页，改为tree控件
api.get('/backend/standardInfo/page', {}).then(res => {
    show.standardInfoList = res.list
    data.total = res.total
})
//TODO 下滑分页，改为tree控件
api.get('/backend/responsibleUnit/page', {}).then(res => {
    show.responsibleUnitList = res.list
    data.total = res.total
})
</script>

<style lang="less"></style>