<template>
  <div class="AMapSearchPoint">
    <el-autocomplete v-show="isSearch" v-model="data.mapInput" style="width: 100%;" :fetch-suggestions="querySearch" placeholder="请输入地址搜素" @select="handleSelect">
      <template #default="{ item }">
        <div style="text-overflow: ellipsis; overflow: hidden;">{{ item.name }}</div>
        <span style="font-size: 12px; color: #b4b4b4;">{{ item.district }}</span>
      </template>
    </el-autocomplete>
    <div id="amap_search_point_container" :style="{ padding: 0, margin: 0, width: '100%', height: mapHeight }" />
  </div>
</template>

<script setup>
import { reactive, defineProps, watch, defineEmits } from 'vue'
import { ElMessage } from 'element-plus'
import AMapLoader from '@amap/amap-jsapi-loader'

const emit = defineEmits(['choosePoint'])

const data = reactive({
  mapInput: '',
  lnglat: null,
  mapMarker: null
})

const props = defineProps({
  mapHeight: {
    type: String,
    default: () => { return '400px' }
  },
  isSearch: {
    type: Boolean,
    default: () => { return true }
  },
  point: {
    type: Array,
    default: () => { return null }
  }
})
watch(() => data.mapMarker, (newValue, oldValue) => {
  if (oldValue) {
    data.map.remove(oldValue)
  }
  if (newValue) {
    data.map.add(newValue)
  }
})
watch(() => props.point, (newValue, oldValue) => {
  if (data.map) {
    markPoint(props.point)
    props.point && data.map.panTo(props.point)
  }
})
// 输入选择
const querySearch = (queryString, callback) => {
  data.autoComplete.search(queryString, (status, result) => {
    if (result && result.tips) {
      callback(result.tips.filter(tip => tip.location))
    } else {
      console.log('高德Key错误', status, result)
      callback([])
    }
  })
}
// 点选搜索结果
const handleSelect = (amapTip) => {
  if (amapTip.location.lng && amapTip.location.lat) {
    emit('choosePoint', [amapTip.location.lng, amapTip.location.lat])
  } else {
    ElMessage.warning('您所选的地点无法精确定位')
  }
}
// 选中点
const markPoint = (lnglatArray) => {
  if (lnglatArray && lnglatArray.length === 2) {
    data.mapMarker = new data.AMap.Marker({
      position: new data.AMap.LngLat(lnglatArray[0], lnglatArray[1])
    })
    data.lnglat = lnglatArray
  } else {
    data.mapMarker = null
  }
}
const initMap = () => {
  AMapLoader.load({
    key: '037a22d7572e65008c52910c9c62b0ba',
    version: '2.0',
    plugins: ['AMap.AutoComplete']
  }).then((AMap) => {
    data.AMap = AMap
    // 加载地图
    let center = [116.838715, 38.304676] // 沧州人民政府
    if (data.defaultPoint && data.defaultPoint.length === 2) {
      center = data.defaultPoint
    }
    data.map = new data.AMap.Map('amap_search_point_container', {
      resizeEnable: true,
      center: center,
      zoom: 12
    })
    if (data.defaultPoint && data.defaultPoint.length === 2) {
      markPoint(center)
    }
    // 点击选取
    data.map.on('click', res => {
      if (props.isSearch) {
        if (res && res.lnglat && res.lnglat.lng && res.lnglat.lat) {
          emit('choosePoint', [res.lnglat.lng, res.lnglat.lat])
        }
      }
    })
    // 输入框选择位置
    data.AMap.plugin(['AMap.AutoComplete'], () => {
      data.autoComplete = new data.AMap.AutoComplete()
    })
    // 初始化位置
    if (props.point) {
      markPoint(props.point)
      props.point && data.map.panTo(props.point)
    }
  }).catch(e => {
    console.error(e)
  })
}
initMap()
</script>

<style scoped></style>